<template>
    <v-container fluid>
        <v-card flat light>
            <v-row align="center">
                <v-col cols="12" sm="6" class="d-flex justify-center">
                    <div>
                        <div class="display-2 font-weight-bold">{{ $t('oops') }}</div>
                        <p class="title">{{$t('access_temporarily_blocked')}}</p>
                        <p class="body-2">{{$t('please_try_again_later_we_apologize')}}</p>
                        <router-link :to="{path: '/'}" class="btn btn-primary btn-hover-shine btn-transition btn-back">{{$t('back')}}</router-link>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-start">
                    <v-img src="/img/error_pages/403.gif" max-height="300" contain></v-img>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: "Blocked"
    };

</script>
